import { useEffect, useContext, createContext, useState } from "react";
import { SinglePageLayoutContext } from "layout/SinglePageLayout";
import { useParams } from "react-router";
import IncomeOption from "./IncomeOption";
import Steps from "common/Steps/Steps";
import VerifyInformation from "components/TakeAnnuityOutOfPlan/VerifyInformation";
import ProvideInformation from "components/TakeAnnuityOutOfPlan/ProvideInformation";
import SubmitInformation from "components/TakeAnnuityOutOfPlan/SubmitInformation";
import labels from "utils/LocalizedStrings";
import SelectIncomeOption from "./SelectIncomeOption";
import { useDispatch, useSelector } from "react-redux";
import cloneDeep from "lodash/cloneDeep";
import { useGetStaticDataQuery } from "service/APIService";
import Spinner from "common/Spinner/Spinner";
import {
  getStateName,
  getCountryName,
  getStateId,
  getRequiredIds,
  convertBlobToFile,
} from "utils/Utilities";
import { setAnnuityPayloadInStore } from "store/commonSlice";
import ErrorMessage from "common/ErrorMessage/ErrorMessage";

const StartIncome = () => {
  const singlePageLayoutContext = useContext(SinglePageLayoutContext);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [attachment, setAttachment] = useState("");
  const [accept, setAccept] = useState(".pdf");
  const [payload, setPayload] = useState({
    activityType: "Income",
    usCitizen: "true",
    nonResidentAlien: "false",
    consentAccepted: false,
    attachmentFormat: "PDF",
    beneficiaryDTOList: [],
    stateCode: "",
    countryCode: "",
    electionType: "S",
  });
  const selectedTaxTypeInStore = useSelector(
    (state) => state.commonReducer.selectedTaxTypeInStore
  );
  const allocationsInStore = useSelector(
    (state) => state.commonReducer.allocationsInStore
  );
  const payloadInStore = useSelector(
    (state) => state.commonReducer.annuityPayloadInStore
  );
  const participantDetails = useSelector(
    (state) => state?.authReducer?.participantDetails
  );
  const balancesInStore = useSelector(
    (state) => state.commonReducer.balancesInStore
  );
  const { data: staticData, isFetching: loading1 } = useGetStaticDataQuery();

  const updatePayload = (value, key) => {
    if (key === "usCitizen" && value === "true") {
      payload["nonResidentAlien"] = "false";
    }
    if (key === "attachmentFormat") {
      setAccept(value === "PDF" ? ".pdf" : ".jpeg");
      sessionStorage.removeItem("image");
      updatePayload("", "attachment");
      setAttachment("");
    }
    payload[key] = value;
    setPayload({ ...payload });
  };

  const startIncomeContext = createContext({
    labels,
    payload,
    setPayload,
    selectedTaxTypeInStore,
    participantDetails,
    updatePayload,
    allocationsInStore,
    staticData,
    getStateName,
    getCountryName,
    setLoading,
    attachment,
    setAttachment,
    getStateId,
    getRequiredIds,
    setErrors,
    balancesInStore,
    accept,
    setAccept,
  });

  useEffect(() => {
    if (payloadInStore && payloadInStore?.activityType) {
      setPayload({ ...cloneDeep(payloadInStore) });
      setAccept(payloadInStore?.attachmentFormat === "PDF" ? ".pdf" : ".jpeg");
    } else {
      const clonedDetails = cloneDeep(participantDetails);
      setPayload({
        ...payload,
        ...clonedDetails,
        stateCode:
          clonedDetails?.usResident === "Y"
            ? clonedDetails?.pptStateCountryCode
            : "",
        countryCode:
          clonedDetails?.usResident === "Y"
            ? "US"
            : clonedDetails?.pptStateCountryCode,
      });
    }
  }, []);

  useEffect(() => {
    singlePageLayoutContext({
      title: labels?.startIncome?.title,
      buttonText: !id
        ? labels?.startIncome?.close
        : labels?.startIncome?.cancel,
      buttonPath: "/dashboard",
      description: "",
      closeButton: !id,
    });
  }, [id]);

  useEffect(() => {
    dispatch(setAnnuityPayloadInStore(cloneDeep(payload)));
  }, [payload]);

  useEffect(() => {
    if (attachment[0]) {
      const blobURL = URL.createObjectURL(attachment[0]);
      const obj = {
        fileType: attachment[0].type,
        fileName: attachment[0].name,
        blobURL: blobURL,
      };
      sessionStorage.setItem("image", JSON.stringify(obj));
    }
  }, [attachment]);

  useEffect(() => {
    const image = JSON.parse(sessionStorage.getItem("image"));
    if (image) {
      convertBlobToFile(image.blobURL, image.fileName, image.fileType)
        .then((res) => {
          if (res) setAttachment([res]);
        })
        .catch(() => {
          setAttachment("");
        });
    }
  }, []);

  if (!id) {
    return (
      <section className="single-page-container">
        <Steps
          title={labels?.startIncome?.yourIncomeOptions}
          description={labels?.startIncome?.yourIncomeOptionsDescription}
        />
        <IncomeOption context={startIncomeContext} />
      </section>
    );
  } else if (id == 1) {
    return (
      <section className="single-page-container">
        {(loading1 || loading) && <Spinner />}
        <Steps
          total="3"
          current={id}
          title={labels?.startIncome?.selectYourIncomeOption}
        />
        <SelectIncomeOption context={startIncomeContext} />
      </section>
    );
  } else if (id == 2) {
    return (
      <section className="single-page-container">
        {(loading1 || loading) && <Spinner />}
        <p className="second-header pt-2 pb-3">
          {labels?.startIncome?.verifyInformationHeader}
        </p>
        <Steps
          total="3"
          current={id}
          title={labels?.startIncome?.verifyInformationTitle}
          description={labels?.startIncome?.verifyInformationDescription}
        />
        <VerifyInformation from="startIncome" context={startIncomeContext} />
      </section>
    );
  } else if (id == 3) {
    return (
      <section className="single-page-container">
        {(loading1 || loading) && <Spinner />}
        {errors && errors.length
          ? errors.map((e, index) => <ErrorMessage key={index} error={e} />)
          : ""}
        <Steps
          total="3"
          current={id}
          title={labels?.startIncome?.provideRequiredInformation}
          description={
            labels?.startIncome?.provideRequiredInformationDescription
          }
        />
        <ProvideInformation from="startIncome" context={startIncomeContext} />
      </section>
    );
  } else if (id == 4) {
    return (
      <section className="single-page-container">
        {(loading1 || loading) && <Spinner />}
        {errors && errors.length
          ? errors.map((e, index) => <ErrorMessage key={index} error={e} />)
          : ""}
        <Steps
          title={labels?.startIncome?.pleaseVerifyRequiredInformationTitle}
          description={
            labels?.startIncome?.pleaseVerifyRequiredInformationDescription
          }
          infoMsg={labels?.startIncome?.infoMsgInSubmitPage}
        />
        <SubmitInformation from="startIncome" context={startIncomeContext} />
      </section>
    );
  }
};

export default StartIncome;
