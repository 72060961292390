import ArrowLink from "common/ArrowLink/ArrowLink";

const Resources = ({ labels, resources, participantDetails }) => {
  const getResourceLink = (data) => {
    const planState = participantDetails?.planStateCode;
    const planId = participantDetails?.extPlanId;
    return data?.link[planState] || data?.link[planId] || data?.link["ALL"];
  };

  const filterResources = (resources) => {
    if (participantDetails?.planStateCode !== "NJ") {
      resources.pop();
    }

    return resources;
  };

  return (
    <div className="section-one py-2 mb-4">
      <div className="header mb-4 py-1">
        {labels?.learningCenter?.resources}
      </div>

      <div className="d-flex flex-wrap links-mobile">
        {filterResources(resources).map((e) => (
          <div
            className="col-md-5"
            key={e.name}
            style={{ marginRight: "50px" }}
          >
            <ArrowLink
              wrapperClassName="links mb-3"
              link={getResourceLink(e)}
              text={e.name}
              description={e.description}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Resources;
