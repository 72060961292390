import { loadConfig } from "utils/Config";

export const baseURL = loadConfig().serviceURL + "/apis/v1";
export const customizationURL = loadConfig().clientCustomizationURL;

export const APIEndpoints = {
  token: "/auth/token",
  refreshToken: "/auth/refresh-token",
  newRefreshToken: "/auth/new-refresh-token",
  modisToken: "/auth/test-page-token",
  labels: (client) => `${customizationURL}/getLabels/${client}`,
  clientConfig: (client) => `${customizationURL}/getClientConfig/${client}`,
  resources: (client) => `${customizationURL}/getResources/${client}`,
  videos: (client) => `${customizationURL}/getVideos/${client}`,
  styles: (client) => `${customizationURL}/${client}/styles.css`,
  images: (client, image) => `${customizationURL}/${client}/images/${image}`,
  getContractAsPDF: "/documents/contracts/get-pdf",
  getStatementAsPDF: "/documents/statements/get-pdf",
  getAmendmentAsPDF: "/documents/amendment/get-amendment",
  getContracts: ({ productSponsorId, productId, ownershipId, planId }) =>
    `/documents/contracts?productSponsorId=${productSponsorId}&productId=${productId}&ownershipId=${ownershipId}&planId=${planId}`,
  getContractsWithAmendment: ({
    productSponsorId,
    productId,
    ownershipId,
    planId,
  }) =>
    `/documents/contracts-with-amendment?productSponsorId=${productSponsorId}&productId=${productId}&ownershipId=${ownershipId}&planId=${planId}`,
  getStatements: ({ productSponsorId, productId, planId, contractId }) =>
    `/documents/statements?productSponsorId=${productSponsorId}&productId=${productId}&planId=${planId}&contractId=${contractId}`,
  getAccumulation: ({ productSponsorId, productId, ownershipId }) =>
    `/ownership/accumulation?productSponsorId=${productSponsorId}&productId=${productId}&ownershipId=${ownershipId}`,
  getTaxTypes: ({ productSponsorId, productId, ownershipId }) =>
    `/ownership/tax-types?productSponsorId=${productSponsorId}&productId=${productId}&ownershipId=${ownershipId}`,
  getBalances: ({ productSponsorId, productId, ownershipId, taxTypeCode }) =>
    `/ownership/balances?productSponsorId=${productSponsorId}&productId=${productId}&ownershipId=${ownershipId}&taxTypeCode=${taxTypeCode}`,
  getAllocations: ({ productSponsorId, productId, ownershipId, taxTypeCode }) =>
    `/ownership/allocations?productSponsorId=${productSponsorId}&productId=${productId}&ownershipId=${ownershipId}&taxTypeCode=${taxTypeCode}`,
  getChangeAllocations: ({
    productSponsorId,
    productId,
    ownershipId,
    planId,
    taxTypeCode,
  }) =>
    `/ownership/change-allocations?productSponsorId=${productSponsorId}&productId=${productId}&ownershipId=${ownershipId}&taxTypeCode=${taxTypeCode}&planId=${planId}`,
  postChangeAllocations: "/ownership/change-allocations",
  getStaticData: () => "/static/data",
  postChangeAnnuity: () => "/ownership/annuity/change-annuity",
  postValidateOwnerInfo: () => "/ownership/annuity/validate-owner-info",
  postValidateBeneficiaries: () => "ownership/annuity/validate-beneficiaries",
  getContractStatus: ({
    productSponsorId,
    productId,
    ownershipId,
    taxTypeCode,
  }) =>
    `/ownership/contract/status?productSponsorId=${productSponsorId}&productId=${productId}&ownershipId=${ownershipId}&taxTypeCode=${taxTypeCode}`,
};
