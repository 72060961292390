const Steps = ({ current, total, title, description, infoMsg }) => {
  return (
    <div className="text-center pb-4" id="Steps">
      <div className="flex-between-center flex-column">
        {current && (
          <div className="stepCount">
            STEP {current} OF {total}
          </div>
        )}
        <div className="title mb-3">{title}</div>
        <div className="description">{description}</div>
        {infoMsg && <div className="info-msg py-2 px-5 mt-3">{infoMsg}</div>}
      </div>
    </div>
  );
};

export default Steps;
