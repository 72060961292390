import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setToken } from "store/authSlice";
import { APIEndpoints, baseURL } from "utils/APIEndpoints";
import { downloadPDF, openPDF } from "utils/Utilities";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseURL,
    prepareHeaders: (headers) => {
      const oneTimeToken = window.location.search.split("=")[1];
      headers.set("oneTimeToken", oneTimeToken);
      headers.set("apiKey", sessionStorage.getItem("apiKey"));
      headers.set("client", localStorage.getItem("tenant"));
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getToken: builder.query({
      query: () => APIEndpoints.token,
    }),
    fetchTestToken: builder.mutation({
      query: (payload) => ({
        url: APIEndpoints.modisToken,
        method: "POST",
        body: payload,
      }),
    }),
    getNewRefreshToken: builder.query({
      query: () => APIEndpoints.newRefreshToken,
    }),
  }),
});

const baseQueryWithToken = fetchBaseQuery({
  baseUrl: baseURL,
  prepareHeaders: (headers, { getState, endpoint }) => {
    const token = getState()?.authReducer?.token;
    const accessToken = token?.accessToken;
    const refreshToken = token?.refreshToken;

    headers.set("client", localStorage.getItem("tenant"));
    headers.set("apiKey", sessionStorage.getItem("apiKey"));
    if (endpoint === "refresh") {
      headers.set("refreshToken", refreshToken);
    } else {
      headers.set("Authorization", `Bearer ${accessToken}`);
    }
    return headers;
  },
});

const fileHandlingApi = async (args, api) => {
  let result = {};
  const headers = {
    Authorization: `Bearer ${api.getState()?.authReducer?.token?.accessToken}`,
    Client: localStorage.getItem("tenant"),
    Apikey: sessionStorage.getItem("apiKey"),
  };
  await fetch(baseURL + args.url, {
    headers,
  })
    .then((response) => {
      if (response?.status == 200) {
        return response.arrayBuffer();
      }
      return Promise.reject(response);
    })
    .then((blob) => {
      if (args?.open) {
        openPDF(blob);
      } else {
        downloadPDF(blob, args.fileName);
      }
    })
    .catch(async (error) => {
      if (error?.status == 401) {
        result = {
          status: error?.status,
        };
      } else {
        result["error"] = await error.json();
      }
    });

  return result;
};

const errorHandlingApi = async (args, api, extraOptions) => {
  let result = "";
  if (args?.type === "blob") {
    result = await fileHandlingApi(args, api);
  } else {
    result = await baseQueryWithToken(args, api, extraOptions);
  }

  if (result?.error?.status == 401 || result?.status == 401) {
    const currentToken = api.getState()?.authReducer?.token;

    const refreshToken = await baseQueryWithToken(
      APIEndpoints.refreshToken,
      { ...api, endpoint: "refresh" },
      extraOptions
    );

    if (refreshToken.data) {
      await api.dispatch(setToken({ ...currentToken, ...refreshToken.data }));
      if (args?.type === "blob") {
        result = await fileHandlingApi(args, api);
      } else {
        result = await baseQueryWithToken(args, api, extraOptions);
      }
    } else {
      api.dispatch(setToken({}));
    }
  }
  return result;
};

export const riccApi = createApi({
  reducerPath: "riccApi",
  baseQuery: errorHandlingApi,
  tags: ["changeAllocations", "changeAnnuity"],
  endpoints: (builder) => ({
    getContractAsPDF: builder.query({
      query: ({ contractId, open }) => ({
        url: APIEndpoints.getContractAsPDF + `?contractId=${contractId}`,
        type: "blob",
        fileName: contractId,
        open: open,
      }),
    }),
    getStatementAsPDF: builder.query({
      query: ({ year, contractId, open }) => ({
        url:
          APIEndpoints.getStatementAsPDF +
          `?year=${year}&contractId=${contractId}`,
        type: "blob",
        fileName: year + "-" + contractId,
        open: open,
      }),
    }),
    getAmendmentAsPDF: builder.query({
      query: ({ year, amendmentName, open }) => ({
        url:
          APIEndpoints.getAmendmentAsPDF +
          `?year=${year}&amendmentName=${amendmentName}`,
        type: "blob",
        fileName: year + "-" + amendmentName,
        open: open,
      }),
    }),
    getContracts: builder.query({
      query: (productSponsorId, productId, ownershipId, planId) =>
        APIEndpoints.getContractsWithAmendment(
          productSponsorId,
          productId,
          ownershipId,
          planId
        ),
    }),
    getStatements: builder.query({
      query: (productSponsorId, productId, planId, contractId) =>
        APIEndpoints.getStatements(
          productSponsorId,
          productId,
          planId,
          contractId
        ),
    }),
    getAccumulation: builder.query({
      query: (productSponsorId, productId, ownershipId) =>
        APIEndpoints.getAccumulation(productSponsorId, productId, ownershipId),
    }),
    getTaxTypes: builder.query({
      query: (productSponsorId, productId, ownershipId) =>
        APIEndpoints.getTaxTypes(productSponsorId, productId, ownershipId),
    }),
    getBalances: builder.query({
      query: (productSponsorId, productId, ownershipId, taxTypeCode) =>
        APIEndpoints.getBalances(
          productSponsorId,
          productId,
          ownershipId,
          taxTypeCode
        ),
    }),
    getAllocations: builder.query({
      query: (productSponsorId, productId, ownershipId, taxTypeCode) =>
        APIEndpoints.getAllocations(
          productSponsorId,
          productId,
          ownershipId,
          taxTypeCode
        ),
    }),
    getChangeAllocations: builder.query({
      query: (productSponsorId, productId, ownershipId, planId, taxTypeCode) =>
        APIEndpoints.getChangeAllocations(
          productSponsorId,
          productId,
          ownershipId,
          planId,
          taxTypeCode
        ),
      providesTags: ["changeAllocations"],
    }),
    postChangeAllocations: builder.mutation({
      query: (payload) => ({
        url: APIEndpoints.postChangeAllocations,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["changeAllocations"],
    }),
    getStaticData: builder.query({
      query: () => APIEndpoints.getStaticData(),
      keepUnusedDataFor: 3000,
    }),
    postChangeAnnuity: builder.mutation({
      query: (payload) => ({
        url: APIEndpoints.postChangeAnnuity(),
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["changeAnnuity"],
    }),
    postValidateOwnerInfo: builder.mutation({
      query: (payload) => ({
        url: APIEndpoints.postValidateOwnerInfo(),
        method: "POST",
        body: payload,
      }),
    }),
    postValidateBeneficiaries: builder.mutation({
      query: (payload) => ({
        url: APIEndpoints.postValidateBeneficiaries(),
        method: "POST",
        body: payload,
      }),
    }),
    getContractStatus: builder.query({
      query: (productSponsorId, productId, ownershipId, taxTypeCode) =>
        APIEndpoints.getContractStatus(
          productSponsorId,
          productId,
          ownershipId,
          taxTypeCode
        ),
      providesTags: ["changeAnnuity"],
    }),
  }),
});

export const {
  useGetTokenQuery,
  useFetchTestTokenMutation,
  useGetNewRefreshTokenQuery,
} = authApi;
export const {
  useGetParticipantInfoMutation,
  useLazyGetContractAsPDFQuery,
  useLazyGetStatementAsPDFQuery,
  useLazyGetAmendmentAsPDFQuery,
  useGetContractsQuery,
  useGetStatementsQuery,
  useGetAccumulationQuery,
  useGetTaxTypesQuery,
  useGetBalancesQuery,
  useGetAllocationsQuery,
  useGetChangeAllocationsQuery,
  usePostChangeAllocationsMutation,
  useGetStaticDataQuery,
  usePostChangeAnnuityMutation,
  usePostValidateOwnerInfoMutation,
  usePostValidateBeneficiariesMutation,
  useGetContractStatusQuery,
} = riccApi;
